import { graphql } from "gatsby";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Sticky, StickyContainer } from "react-sticky";

import { OrderStore, UIStore, VisitorStore } from "stores";

import Input from "../../components/input";
import Checkbox from "../../components/checkbox";
import Button from "../../components/button";
import SelectList, { SelectListItem } from "../../components/selectList";
import ShopHeader from "../../components/shopHeader";
import { getOrderTotals } from "../../utils/totals";
import SEO from "../../components/seo";
import { PAGE_VIEW_EVENT, NAVIGATE_EVENT, INPUT_DETAILS_EVENT } from "../../utils/constants";

@inject("orderStore", "shopStore", "uiStore", "visitorStore")
@observer
class CustomerDetailsPage extends React.Component<any> {
  @observable invoiceOpen: boolean;
  constructor(props: any) {
    super(props);
    this.invoiceOpen = false;
  }
  componentWillMount() {
    const store: OrderStore = this.props.orderStore;
    const customer = store.current.customer;
    const countries = this.props.data.squidexConfig.countries[process.env.GATSBY_LANGUAGE];

    if (!customer.country) {
      customer.country = countries[0].iso2Code;
    }
    if (!customer.invoiceCountry) {
      customer.invoiceCountry = countries[0].iso2Code;
    }
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "shop-customerDetails"
    });
  }
  render() {
    const texts = this.props.data.squidexTexts;
    return (
      <>
        <SEO subTitle={texts.pageShopCustomerDetails[process.env.GATSBY_LANGUAGE]} />
        <ShopHeader location={this.props.location} />
        <div className="flex justify-between relative">
          <div className="w-100 w-50-l dib">
            {this.renderCustomerFields()}
            {this.renderInvoiceFields()}
            <div className="cf mt2 mb2">
              <Button className="fl" size={4} color="theme" inversed={true} label={texts.btnPreviousStep[process.env.GATSBY_LANGUAGE]} onClick={this.handleEditCart} />
              <Button className="fr" size={4} color="theme" label={texts.btnNextStep[process.env.GATSBY_LANGUAGE]} onClick={this.handleNextStep} />
            </div>
          </div>
          {this.renderStickyCart()}
        </div>
      </>
    )
  }
  renderCustomerFields() {
    const texts = this.props.data.squidexTexts;
    const countries = this.props.data.squidexConfig.countries[process.env.GATSBY_LANGUAGE].map(({ label, iso2Code }) => ({ label, value: iso2Code }));
    const store: OrderStore = this.props.orderStore;
    const customer = store.current.customer;
    const selectedItem = countries.find(c => c.value === customer.country);
    return (
      <div className="mw6 mt2-ns mt3 center mr0-l ml0-l b--black-20 mb2">
        <h3 className="f4 bg-theme white br--top-ns mv0 pv2 ph3 tc">{texts.txtCustomerDeliveryAddress[process.env.GATSBY_LANGUAGE]}</h3>
        <div className="pa3-ns bt b--black-10 mw6 ba-ns">
          <p className="f7 lh-copy mb0">{texts.txtRequiredInfo[process.env.GATSBY_LANGUAGE]}</p>
          <Input initialValue={customer.firstName} className="mb2" label={`${texts.txtCustomerFirstName[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("firstName")} />
          <Input initialValue={customer.lastName} className="mb2" label={`${texts.txtCustomerLastName[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("lastName")} />
          <Input initialValue={customer.corporation} className="mb2" label={texts.txtCustomerCorporation[process.env.GATSBY_LANGUAGE]} horizontal={true} onChange={this.handleCustomerPropChange("company")} />
          <SelectList defaultValue={selectedItem} className="mb2" label={`${texts.txtCustomerCountry[process.env.GATSBY_LANGUAGE]} *`} placeholder={texts.txtCustomerCountryPlaceholder[process.env.GATSBY_LANGUAGE]} items={countries} onChange={this.handleCustomerCountryChange} horizontal={true} />
          <Input initialValue={customer.postalCode} className="mb2" label={`${texts.txtCustomerZipCode[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("postalCode")} />
          <Input initialValue={customer.street} className="mb2" label={`${texts.txtCustomerAddressLine[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("street")} />
          <Input initialValue={customer.place} className="mb2" label={`${texts.txtCustomerPlace[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("place")} />
          <Input initialValue={customer.email} className="mb2" label={`${texts.txtCustomerEmail[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("email")} />
          <Checkbox label={texts.txtSameInvoiceAddress[process.env.GATSBY_LANGUAGE]} checked={!this.invoiceOpen} onChange={this.handleOpenInvoiceChange} />
        </div>
      </div>
    )
  }
  renderInvoiceFields = () => {
    if (!this.invoiceOpen) {
      return null;
    }
    const countries = [{ label: "Nederland", value: "NL" }, { label: "Duitsland", value: "DE" }];
    const store: OrderStore = this.props.orderStore;
    const customer = store.current.customer;
    const texts = this.props.data.squidexTexts;
    const selectedValue = countries.find(c => c.value === customer.invoiceCountry);
    return (
      <div className="mw6 mt2-ns mt3 center mr0-l ml0-l b--black-20 mb2">
        <h3 className="f4 bg-theme white br--top-ns mv0 pv2 ph3 tc">{texts.txtCustomerInvoiceAddress[process.env.GATSBY_LANGUAGE]}</h3>
        <div className="pa3 bt b--black-10 mw6 ba-ns">
          <Input initialValue={customer.invoiceFirstName} className="mb2" label={`${texts.txtCustomerInvoiceFirstName[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("invoiceFirstName")} />
          <Input initialValue={customer.invoiceLastName} className="mb2" label={`${texts.txtCustomerInvoiceLastName[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("invoiceLastName")} />
          <Input initialValue={customer.invoiceCorporation} className="mb2" label={texts.txtCustomerInvoiceCorporation[process.env.GATSBY_LANGUAGE]} horizontal={true} onChange={this.handleCustomerPropChange("invoiceCorporation")} />
          <SelectList className="mb2" defaultValue={selectedValue} placeholder={texts.txtCustomerInvoiceCountry[process.env.GATSBY_LANGUAGE]} label={`${texts.txtCustomerInvoiceCountry[process.env.GATSBY_LANGUAGE]} *`} items={countries} onChange={this.handleCustomerInvoiceCountryChange} horizontal={true} />
          <Input initialValue={customer.invoicePostalCode} className="mb2" label={`${texts.txtCustomerInvoiceZipCode[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("invoicePostalCode")} />
          <Input initialValue={customer.invoiceStreet} className="mb2" label={`${texts.txtCustomerInvoiceAddress[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("invoiceStreet")} />
          <Input initialValue={customer.invoicePlace} className="mb2" label={`${texts.txtCustomerInvoicePlace[process.env.GATSBY_LANGUAGE]} *`} horizontal={true} onChange={this.handleCustomerPropChange("invoicePlace")} />
        </div>
      </div>
    )
  }
  renderStickyCart = () => {
    const texts = this.props.data.squidexTexts;
    let right = 0;
    if (typeof (window) !== "undefined") {
      right = ((document.body.clientWidth - 1024) / 2);
    }
    return (
      <StickyContainer>
        <Sticky>{({ style }) => {
          return (
            <div style={{ ...style, width: undefined, left: undefined, right }} className="dn dib-l w5">
              <div className="mt2 w-100 ba b--black-10">
                <h3 className="f4 bg-near-white br--top-ns mv0 pv2 ph3 tc">{texts.pageShopCart[process.env.GATSBY_LANGUAGE]}</h3>
                <div className="ph2 ph0-ns w-100 bt b--black-10 flex flex-column items-center">
                  <div className="mt2 w-100 flex justify-between bb b--black-10 pv2 ph3">
                    <span className="b w4">{texts.txtProduct[process.env.GATSBY_LANGUAGE]}</span>
                    <span className="b tr">{texts.txtAmount[process.env.GATSBY_LANGUAGE]}</span>
                    <span className="b tr w3">{texts.txtPricePerPiece[process.env.GATSBY_LANGUAGE]}</span>
                  </div>
                  {this.renderCartItems()}
                  {this.renderCartTotals()}
                  <Button className="ma2" size={4} label={texts.txtEdit[process.env.GATSBY_LANGUAGE]} inversed={true} color="theme" onClick={this.handleEditCart} />
                </div>
              </div>
            </div >
          )
        }}</Sticky>
      </StickyContainer>
    )
  }
  renderCartItems = () => {
    const store: OrderStore = this.props.orderStore;
    return store.current.items.map((item, index) => (
      <div key={`customer-cart-${index}`} className="w-100 flex justify-between pv2 ph3">
        <span className="w4">{item.material.name}</span>
        <span className="tr">{item.amount}x</span>
        <span className="tr w3">&euro; {item.price.toFixed(2)}</span>
      </div>
    ))
  }
  renderCartTotals = () => {
    const { discount, deliverCost, giftAmount, total } = getOrderTotals(
      this.props.shopStore,
      this.props.orderStore
    );
    const texts = this.props.data.squidexTexts;
    return <>
      <div className={`${discount > 0 ? "flex" : "dn"} w-100 justify-between pv2 ph3 bt b--black-10`}>
        <span className="w4">{texts.txtDiscount[process.env.GATSBY_LANGUAGE]}</span>
        <span className="tr"></span>
        <span className="tr w3">&euro; -{discount.toFixed(2)}</span>
      </div>
      <div className={`${deliverCost > 0 ? "flex" : "dn"} w-100 justify-between pv2 ph3`}>
        <span className="w4">{texts.txtDeliverCosts[process.env.GATSBY_LANGUAGE]}</span>
        <span className="tr"></span>
        <span className="tr w3">&euro; {deliverCost.toFixed(2)}</span>
      </div>
      <div className={`${giftAmount > 0 ? "flex" : "dn"} w-100 justify-between pv2 ph3`}>
        <span className="w4">{texts.txtGiftcard[process.env.GATSBY_LANGUAGE]}</span>
        <span className="tr"></span>
        <span className="tr w3">&euro; -{giftAmount.toFixed(2)}</span>
      </div>
      <div className="flex w-100 justify-between pv2 ph3 bt b--black-10">
        <span className="b w4">{texts.txtTotal[process.env.GATSBY_LANGUAGE]}</span>
        <span className="tr"></span>
        <span className="b tr w3">&euro; {total.toFixed(2)}</span>
      </div>
    </>
  }

  handleOpenInvoiceChange = (checked: boolean) => {
    this.invoiceOpen = !checked;
  }
  handleCustomerPropChange = (propName: string) => {
    return (value: string) => {
      const store: OrderStore = this.props.orderStore;
      (store.current.customer as any)[propName] = value;
      const visitorStore: VisitorStore = this.props.visitorStore;
      visitorStore.logEvent(INPUT_DETAILS_EVENT, propName);
    }
  }
  handleCustomerCountryChange = (item: SelectListItem) => {
    const store: OrderStore = this.props.orderStore;
    store.current.customer.country = item.value;
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(INPUT_DETAILS_EVENT, "country");
  }
  handleCustomerInvoiceCountryChange = (item: SelectListItem) => {
    const store: OrderStore = this.props.orderStore;
    store.current.customer.invoiceCountry = item.value;
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(INPUT_DETAILS_EVENT, "invoiceCountry");
  }
  handleEditCart = () => {
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(NAVIGATE_EVENT, {
      location: this.props.location,
      href: this.props.data.squidexConfig.pageCartSlug.iv.toLowerCase()
    });
    this.props.navigate(this.props.data.squidexConfig.pageCartSlug.iv.toLowerCase());
  }
  handleNextStep = () => {
    if (!this.invoiceOpen) {
      const store: OrderStore = this.props.orderStore;
      const customer = store.current.customer;
      customer.invoiceCorporation = customer.corporation;
      customer.invoiceCountry = customer.country;
      customer.invoiceFirstName = customer.firstName;
      customer.invoiceHouseNumber = customer.houseNumber;
      customer.invoiceLastName = customer.lastName;
      customer.invoicePlace = customer.place;
      customer.invoicePostalCode = customer.postalCode;
      customer.invoiceStreet = customer.street;
    }
    if (!this.validate()) {
      return;
    }
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(NAVIGATE_EVENT, {
      location: this.props.location,
      href: this.props.data.squidexConfig.pageOrderSlug.iv.toLowerCase()
    });

    this.props.navigate(this.props.data.squidexConfig.pageOrderSlug.iv.toLowerCase());
  }
  validate = () => {
    const store: OrderStore = this.props.orderStore;
    const uiStore: UIStore = this.props.uiStore;
    const texts = this.props.data.squidexTexts;
    const customer = store.current.customer;
    if (!customer.firstName) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerFirstNameError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.lastName) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerLastNameError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.country) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerCountryError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.postalCode) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerPostalCodeError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.street) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerAddressLineError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.place) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerPlaceError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.email) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerEmailError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.invoiceFirstName) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerInvoiceFirstNameError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.invoiceLastName) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerInvoiceLastNameError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.invoiceCountry) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerInvoiceCountryError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.invoicePostalCode) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerInvoicePostalCodeError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.invoiceStreet) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerInvoiceAddressLineError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    if (!customer.invoicePlace) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoCustomerInvoicePlaceError[process.env.GATSBY_LANGUAGE]);
      return false;
    }
    return true;
  }
}

export default CustomerDetailsPage;
export const pageQuery = graphql`
{
  squidexConfig(identity: {eq: "config"}) {
    pageCartSlug {
      iv
    }
    pageOrderSlug {
      iv
    }
    countries {
      de {
        label
        iso2Code
      }
      nl {
        label
        iso2Code
      }
      en {
        label
        iso2Code
      }
    }
  }
  squidexTexts(identity: {eq: "squidextexts"}) {
    pageShopCart {
      de
      nl
      en
    }
    pageShopCustomerDetails {
      de
      nl
      en
    }
    txtCustomerDeliveryAddress {
      de
      nl
      en
    }
    txtCustomerFirstName {
      de
      nl
      en
    }
    txtCustomerLastName {
      de
      nl
      en
    }
    txtCustomerCorporation {
      de
      nl
      en
    }
    txtCustomerCountry {
      de
      nl
      en
    }
    txtCustomerCountryPlaceholder {
      de
      nl
      en
    }
    txtCustomerZipCode {
      de
      nl
      en
    }
    txtCustomerAddressLine {
      de
      nl
      en
    }
    txtCustomerPlace {
      de
      nl
      en
    }
    txtCustomerEmail {
      de
      nl
      en
    }
    txtSameInvoiceAddress {
      de
      nl
      en
    }
    txtProduct {
      de
      nl
      en
    }
    txtAmount {
      de
      nl
      en
    }
    txtPricePerPiece {
      de
      nl
      en
    }
    txtTotal {
      de
      nl
      en
    }
    txtEdit {
      de
      nl
      en
    }
    btnNextStep {
      de
      nl
      en
    }
    btnPreviousStep {
      de
      nl
      en
    }
    txtCustomerInvoiceAddress {
      de
      nl
      en
    }
    txtCustomerInvoiceFirstName {
      de
      nl
      en
    }
    txtCustomerInvoiceLastName {
      de
      nl
      en
    }
    txtCustomerInvoiceCorporation {
      de
      nl
      en
    }
    txtCustomerInvoiceCountry {
      de
      nl
      en
    }
    txtCustomerInvoiceCountryPlaceholder {
      de
      nl
      en
    }
    txtCustomerInvoiceZipCode {
      de
      nl
      en
    }
    txtCustomerInvoicePlace {
      de
      nl
      en
    }
    txtGiftcard {
      de
      nl
      en
    }
    txtDiscount {
      de
      nl
      en
    }
    txtDeliverCosts {
      de
      nl
      en
    }
    txtValidationErrorHeader {
      de
      nl
      en
    }
    txtNoCustomerFirstNameError {
      de
      nl
      en
    }
    txtNoCustomerLastNameError {
      de
      nl
      en
    }
    txtNoCustomerCountryError {
      de
      nl
      en
    }
    txtNoCustomerPostalCodeError {
      de
      nl
      en
    }
    txtNoCustomerAddressLineError {
      de
      nl
      en
    }
    txtNoCustomerPlaceError {
      de
      nl
      en
    }
    txtNoCustomerEmailError {
      de
      nl
      en
    }
    txtNoCustomerInvoiceFirstNameError {
      de
      nl
      en
    }
    txtNoCustomerInvoiceLastNameError {
      de
      nl
      en
    }
    txtNoCustomerInvoiceCountryError {
      de
      nl
      en
    }
    txtNoCustomerInvoicePostalCodeError {
      de
      nl
      en
    }
    txtNoCustomerInvoiceAddressLineError {
      de
      nl
      en
    }
    txtNoCustomerInvoicePlaceError {
      de
      nl
      en
    }
    txtRequiredInfo {
      de
      nl
      en
    }
  }
}
`

